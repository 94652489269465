import gql from 'graphql-tag'


const listResponse = `
  id type order title location area publishedAt
`

export const LIST_PROJECT = (templateType) => gql`query LIST_PROJECT ($projectType: String!, $q: ${templateType}FilterInput) {
  projects: list${templateType} (projectType: $projectType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type order productId
  title coverId location area imageIds doneAt
  createdAt publishedAt
`

export const DETAIL_PROJECT = (templateType) => gql`query DETAIL_PROJECT ($projectType: String!, $projectId: Int!) {
  project: detail${templateType} (projectType: $projectType, projectId: $projectId) {${detailResponse}}
}`

export const CREATE_PROJECT = (templateType) => gql`mutation CREATE_PROJECT ($projectType: String!, $input: ${templateType}CreateInput!) {
  createProject: create${templateType} (projectType: $projectType, input: $input) {${detailResponse}}
}`

export const UPDATE_PROJECT = (templateType) => gql`mutation UPDATE_PROJECT ($projectType: String!, $projectId: Int!, $input: ${templateType}UpdateInput!) {
  updateProject: update${templateType} (projectType: $projectType, projectId: $projectId, input: $input) {${detailResponse}}
}`

export const DESTROY_PROJECT = (templateType) => gql`mutation DESTROY_PROJECT ($projectType: String!, $projectId: Int!) {
  destroyProject: destroy${templateType} (projectType: $projectType, projectId: $projectId) {id}
}`

export const PUBLISH_PROJECT = (templateType) => gql`mutation PUBLISH_PROJECT ($projectType: String!, $projectId: Int!) {
  publishProject: publish${templateType} (projectType: $projectType, projectId: $projectId) {${detailResponse}}
}`

export const UNPUBLISH_PROJECT = (templateType) => gql`mutation UNPUBLISH_PROJECT ($projectType: String!, $projectId: Int!) {
  unpublishProject: unpublish${templateType} (projectType: $projectType, projectId: $projectId) {${detailResponse}}
}`
import gql from 'graphql-tag'


export const LIST_FOLDER_PATH = (templateType) => gql`query LIST_FOLDER_PATH ($assetType: String!, $folderId: Int!) {
  folders: list${templateType}FolderPath (assetType: $assetType, folderId: $folderId) {
    id type name parentId
  }
}`

export const LIST_FOLDER = (templateType) => gql`query LIST_FOLDER ($assetType: String!, $parentId: Int, $q: ${templateType}FolderFilterInput) {
  folders: list${templateType}Folder (assetType: $assetType, parentId: $parentId, q: $q) {
    id type name parentId
  }
}`
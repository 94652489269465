<template>
  <span class="">
    <slot v-bind="{toggle}"></slot>

    <b-modal 
      v-model="show" 
      :title="title" 
      size="lg"
      @ok="handleOk">
      <AssetFormInputModalFolder
        :assetType="assetType"
        :templateType="templateType"
        :folderId.sync="folderId">
      </AssetFormInputModalFolder>
      
      <AssetFormInputModalAsset
        :assetType="assetType"
        :templateType="templateType"
        :folderId="folderId"
        :selected="newAssetIds"
        @select="onSelect">
      </AssetFormInputModalAsset>
    </b-modal>
  </span>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import AssetFormInputModalFolder from './AssetFormInputModalFolder.vue'
import AssetFormInputModalAsset from './AssetFormInputModalAsset.vue'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      required: false
    },
    assetIds: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      rKey: `ChronowoodthCore${this.$form.capitalize(this.assetType)}AssetFormInput`,
      rFields: ['folderId'],
      show: false,
      folderId: null,
      newAssetIds: []
    }
  },
  methods: {
    toggle () {
      this.show = !this.show
    },
    onSelect (assetId) {
      let arr = [...this.newAssetIds]

      const index = arr.findIndex(v => v === assetId)
      
      if (index === -1 && this.multiple) arr.push(assetId)
      else if (index === -1 && !this.multiple) arr = [assetId]
      else arr.splice(index, 1)

      this.newAssetIds = arr
    },
    handleOk () {
      this.$emit('update:assetIds', this.newAssetIds)
    }
  },
  watch: {
    folderId: 'setRetaining',
    assetIds: {
      handler (v) {
        this.newAssetIds = v
      },
      immediate: true
    }
  },
  components: {
    AssetFormInputModalFolder,
    AssetFormInputModalAsset
  }
}
</script>

<style lang="css" scoped>
</style>

<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">รายการ</h3>
      </div>
    </div>

    <hr class="my-3">

    <div class="row">
      <div class="col-12">
        <sgv-form :method.sync="method" :options="options">
          <DetailForm
            :projectId="projectId"
            :projectType="projectType"
            :assetType="assetType"
            :productType="productType"
            :templateType="templateType"
            :formData="formData"
            :method="method"
            :v="$v">
          </DetailForm>
        </sgv-form>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm.vue'
import {
  DETAIL_PROJECT,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DESTROY_PROJECT,
  PUBLISH_PROJECT,
  UNPUBLISH_PROJECT,
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: this.formData.title
    }
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    projectType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    productType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      method: 'info',
      listView: `ChronowoodthProject${this.$form.capitalize(this.projectType)}List`,
      rKey: `ChronowoodthProject${this.$form.capitalize(this.projectType)}Detail`,
      formData: {
        order: 0,
        productId: null,

        title: '',
        coverId: null,

        location: '',
        area: '',
        imageIds: [],
        doneAt: null,

        createdAt: null,
        publishedAt: null
      }
    }
  },
  validations: {
    formData: {
      order: { required },
      productId: { required },
      title: { required },
      coverId: { required },
      location: { required },
      area: { required },
      doneAt: { required },
    }
  },
  computed: {
    options () {
      return [
        {text: 'เพิ่ม', variant: 'success', method: 'add', func: this.createData, disabled: true},
        {text: 'แก้ไข', variant: 'warning', method: 'edit', func: this.updateData, disabled: !this.$auth.hasRole(`chronowoodthProject:${this.projectType}:add`) || this.method === 'add'},
        {text: 'ลบ', variant: 'danger', method: 'delete', func: this.destroyData, disabled: !this.$auth.hasRole(`chronowoodthProject:${this.projectType}:add`) || this.method === 'add'},
        {text: 'แผยแพร่', variant: 'info', method: 'publish', func: this.publishData, disabled: !this.$auth.hasRole(`chronowoodthProject:${this.projectType}:add`) || this.method === 'add' || !!this.formData.publishedAt},
        {text: 'ยกเลิก', variant: 'danger', method: 'unpublish', func: this.unpublishData, disabled: !this.$auth.hasRole(`chronowoodthProject:${this.projectType}:add`) || this.method === 'add' || !this.formData.publishedAt},
        {text: 'กลับ', variant: 'primary', func: this.closeForm, header: true, align: 'right'},
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_PROJECT(this.templateType),
        variables: {
          projectType: this.projectType,
          projectId: this.projectId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.project)})
      .catch(err => {this.$toasted.global.error(err)})
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'doneAt') {
          this.formData[key] = this.$date.format(v[key]).date
          return
        }
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const obj = {
        ...v,
        doneAt: this.$date.format(v.doneAt).utc
      }
      delete obj.createdAt
      delete obj.publishedAt
      return obj
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_PROJECT(this.templateType),
        variables: {
          projectType: this.projectType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, projectId: res.data.createProject.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_PROJECT(this.templateType),
        variables: {
          projectType: this.projectType,
          projectId: this.projectId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateProject)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_PROJECT(this.templateType),
        variables: {
          projectType: this.projectType,
          projectId: this.projectId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    publishData () {
      this.$apollo.mutate({
        mutation: PUBLISH_PROJECT(this.templateType),
        variables: {
          projectType: this.projectType,
          projectId: this.projectId
        }
      })
      .then(res => {
        this.setFormData(res.data.publishProject)
        this.method = 'info'
        this.$toasted.global.success("แผยแพร่สำเร็จ")
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    unpublishData () {
      this.$apollo.mutate({
        mutation: UNPUBLISH_PROJECT(this.templateType),
        variables: {
          projectType: this.projectType,
          projectId: this.projectId
        }
      })
      .then(res => {
        this.setFormData(res.data.unpublishProject)
        this.method = 'info'
        this.$toasted.global.success("ยกเลิกสำเร็จ")
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.projectId === 0) {
      this.method = 'add'
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
  }
}
</script>

<style lang="css" scoped>
</style>

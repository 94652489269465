<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bg-light">
      <li :key="0" class="breadcrumb-item pointer" @click="emitFolder(null)">Root</li>
      <template v-if="folderId">
        <li 
          v-for="folder in folders"
          :key="folder.id"
          class="breadcrumb-item pointer"
          @click="emitFolder(folder.id)">
          {{folder.name}}
        </li>
      </template>
    </ol>
  </nav>
</template>

<script>
import { LIST_FOLDER_PATH } from './graph/folder'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    folderId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      folders: []
    }
  },
  apollo: {
    folders: {
      query () {
        return LIST_FOLDER_PATH(this.templateType)
      },
      variables () {
        return {
          assetType: this.assetType,
          folderId: this.folderId
        }
      },
      skip () {
        return !this.folderId
      }
    }
  },
  methods: {
    emitFolder (folderId) {
      this.$emit('update:folderId', folderId)
    },
    emitChange () {
      this.$emit('change', null)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
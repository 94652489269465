<template>
  <div>
    <div class="form-row">
      <ProductFormInput
        label="สินค้า"
        class="col-12 col-md-10"
        :disabled="$auth.disabled(method)"
        :productType="productType"
        select="id"
        templateType="ChronowoodthProduct"
        v-model="formData.productId"
        :validations="[
          {text: 'required!', value: v.formData.productId.$dirty && v.formData.productId.$error}
        ]">
      </ProductFormInput>

      <sgv-input-number
        :disabled="$auth.disabled(method)"
        class="col-12 col-md-2"
        label="ลำดับ"
        v-model="formData.order"
        :validations="[
          {text: 'required!', value: v.formData.order.$dirty && v.formData.order.$error}
        ]">
      </sgv-input-number>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="ชื่อ"
        v-model="formData.title"
        :validations="[
          {text: 'required!', value: v.formData.title.$dirty && v.formData.title.$error}
        ]">
      </sgv-input-text>

      <AssetFormInput
        label="รูปหน้าปก"
        class="col-12"
        maxWidth="250px"
        :disabled="$auth.disabled(method)"
        :assetType="assetType"
        templateType="ChronowoodthAsset"
        v-model="formData.coverId"
        :validations="[
          {text: 'required!', value: v.formData.coverId.$dirty && v.formData.coverId.$error}
        ]">
      </AssetFormInput>
    </div>

    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="สถานที่ติดตั้ง"
        v-model="formData.location"
        :validations="[
          {text: 'required!', value: v.formData.location.$dirty && v.formData.location.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="พื้นที่ตารางเมตร"
        v-model="formData.area"
        :validations="[
          {text: 'required!', value: v.formData.area.$dirty && v.formData.area.$error}
        ]">
      </sgv-input-text>

      <sgv-input-date
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="วันที่ติดตั้ง"
        v-model="formData.doneAt"
        :validations="[
          {text: 'required!', value: v.formData.doneAt.$dirty && v.formData.doneAt.$error}
        ]">
      </sgv-input-date>

      <AssetFormArray
        label="รูปภาพ"
        class="col-12"
        :disabled="$auth.disabled(method)"
        :assetType="assetType"
        :templateType="templateType"
        v-model="formData.imageIds">
      </AssetFormArray>
    </div>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-6"
        label="วันที่สร้าง"
        :value="$date.format(formData.createdAt).displayThai">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-6"
        label="วันที่เผยแพร่"
        :value="formData.publishedAt ? $date.format(formData.publishedAt).displayThai : ''">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
import AssetFormInput from '@/views/chronowoodth_core/AssetFormInput.vue'
import AssetFormArray from '@/views/chronowoodth_core/AssetFormArray.vue'
import ProductFormInput from '@/views/chronowoodth_core/ProductFormInput.vue'

export default {
  props: {
    projectId: {
      type: Number,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    productType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  components: {
    AssetFormInput,
    AssetFormArray,
    ProductFormInput,
  }
}
</script>

<style lang="css">
</style>

import gql from 'graphql-tag'

export const LIST_ASSET = (templateType) => gql`query LIST_ASSET ($assetType: String!, $q: ${templateType}FilterInput) {
  assets: list${templateType} (assetType: $assetType, q: $q) {
    id type filename mimeType
    size width height acl
    folderId folder {id name}
    url
  }
}`

export const DETAIL_ASSET = (templateType) => gql`query DETAIL_ASSET ($assetType: String!, $assetId: Int!) {
  asset: detail${templateType} (assetType: $assetType, assetId: $assetId) {
    id type filename url mimeType
  }
}`
<template>
  <div>
    <AssetFormInputModelFolderPath
      :templateType="templateType"
      :assetType="assetType"
      :folderId="folderId"
      @update:folderId="emitFolder">
    </AssetFormInputModelFolderPath>

    <div class="row">
      <div 
        v-for="folder in folders" 
        :key="folder.id"
        class="col-6 col-md-4 mb-3">
        <div class="card pointer" @click="emitFolder(folder.id)">
          <div class="card-body">
            <span class="text-warning mr-2"><fa icon="folder"></fa></span>
            <span class="card-text">{{ folder.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LIST_FOLDER } from './graph/folder'
import AssetFormInputModelFolderPath from './AssetFormInputModelFolderPath.vue'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    folderId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {

    }
  },
  apollo: {
    folders: {
      query () {
        return LIST_FOLDER(this.templateType)
      },
      variables () {
        return {
          assetType: this.assetType,
          parentId: this.folderId,
          q: {
            orderBy: 'id'
          }
        }
      }
    }
  },
  methods: {
    emitFolder (folderId) {
      this.$emit('update:folderId', folderId)
    },
  },
  components: {
    AssetFormInputModelFolderPath
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="row">
    <div class="col-6 col-sm-4 col-md-3 pointer"
      v-for="(item, i) in assets" 
      :key="i"
      @click="emitInput(item.id)">
      <div :class="onSelectStyle(item.id)">
        <img 
          v-if="isImage(item.mimeType)"
          :src="item.url" 
          class="img-fluid" 
          :alt="item.filename">
        <div style="word-wrap: break-word;">
          {{item.filename}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { 
  LIST_ASSET
} from './graph/asset'

export default {
  props: {
    folderId: {
      type: Number,
      required: false
    },
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    selected: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      assets: [],
    }
  },
  apollo: {
    assets: {
      query () {
        return LIST_ASSET(this.templateType)
      },
      variables () {
        return {
          assetType: this.assetType,
          q: {
            folderId: this.folderId,
            orderBy: 'filename'
          }
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    isImage (mimeType) {
      return mimeType.includes('image/')
    },
    emitInput (assetId) {
      this.$emit('select', assetId)
    },
    onSelectStyle (assetId) {
      if (!this.selected.includes(assetId)) return ''

      return 'p-2 bg-secondary text-light'
    }
  }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-row"},[_c('ProductFormInput',{staticClass:"col-12 col-md-10",attrs:{"label":"สินค้า","disabled":_vm.$auth.disabled(_vm.method),"productType":_vm.productType,"select":"id","templateType":"ChronowoodthProduct","validations":[
        {text: 'required!', value: _vm.v.formData.productId.$dirty && _vm.v.formData.productId.$error}
      ]},model:{value:(_vm.formData.productId),callback:function ($$v) {_vm.$set(_vm.formData, "productId", $$v)},expression:"formData.productId"}}),_c('sgv-input-number',{staticClass:"col-12 col-md-2",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ลำดับ","validations":[
        {text: 'required!', value: _vm.v.formData.order.$dirty && _vm.v.formData.order.$error}
      ]},model:{value:(_vm.formData.order),callback:function ($$v) {_vm.$set(_vm.formData, "order", $$v)},expression:"formData.order"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
        {text: 'required!', value: _vm.v.formData.title.$dirty && _vm.v.formData.title.$error}
      ]},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('AssetFormInput',{staticClass:"col-12",attrs:{"label":"รูปหน้าปก","maxWidth":"250px","disabled":_vm.$auth.disabled(_vm.method),"assetType":_vm.assetType,"templateType":"ChronowoodthAsset","validations":[
        {text: 'required!', value: _vm.v.formData.coverId.$dirty && _vm.v.formData.coverId.$error}
      ]},model:{value:(_vm.formData.coverId),callback:function ($$v) {_vm.$set(_vm.formData, "coverId", $$v)},expression:"formData.coverId"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"สถานที่ติดตั้ง","validations":[
        {text: 'required!', value: _vm.v.formData.location.$dirty && _vm.v.formData.location.$error}
      ]},model:{value:(_vm.formData.location),callback:function ($$v) {_vm.$set(_vm.formData, "location", $$v)},expression:"formData.location"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"พื้นที่ตารางเมตร","validations":[
        {text: 'required!', value: _vm.v.formData.area.$dirty && _vm.v.formData.area.$error}
      ]},model:{value:(_vm.formData.area),callback:function ($$v) {_vm.$set(_vm.formData, "area", $$v)},expression:"formData.area"}}),_c('sgv-input-date',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"วันที่ติดตั้ง","validations":[
        {text: 'required!', value: _vm.v.formData.doneAt.$dirty && _vm.v.formData.doneAt.$error}
      ]},model:{value:(_vm.formData.doneAt),callback:function ($$v) {_vm.$set(_vm.formData, "doneAt", $$v)},expression:"formData.doneAt"}}),_c('AssetFormArray',{staticClass:"col-12",attrs:{"label":"รูปภาพ","disabled":_vm.$auth.disabled(_vm.method),"assetType":_vm.assetType,"templateType":_vm.templateType},model:{value:(_vm.formData.imageIds),callback:function ($$v) {_vm.$set(_vm.formData, "imageIds", $$v)},expression:"formData.imageIds"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":"","label":"วันที่สร้าง","value":_vm.$date.format(_vm.formData.createdAt).displayThai}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":"","label":"วันที่เผยแพร่","value":_vm.formData.publishedAt ? _vm.$date.format(_vm.formData.publishedAt).displayThai : ''}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
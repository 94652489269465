<template>
  <div class="form-group">
    <sgv-input-text
      :value="formData.filename"
      :label="label"
      disabled
      :validations="validations">
      <label slot="label" v-if="!disabled">
        <AssetFormInputModal 
          templateType="ChronowoodthAsset"
          :assetType="assetType"
          :title="label"
          :assetIds.sync="assetIds">
          <template v-slot="{toggle}">
            <span class="text-warning px-2 pointer" @click="toggle">
              <fa icon="folder"></fa>
            </span>
          </template>
        </AssetFormInputModal>
      </label>
    </sgv-input-text>
    <div v-if="formData.url && isImage(formData.mimeType)">
      <img
        :src="formData.url" 
        class="img-fluid"
        :style="{'max-width': maxWidth}"
        :alt="formData.filename">
    </div>
  </div>
</template>

<script>
import AssetFormInputModal from './AssetFormInputModal.vue'
import { DETAIL_ASSET } from './graph/asset.js'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    label: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      assetIds: [],
      formData: {
        filename: '',
        url: '',
        mimeType: ''
      }
    }
  },
  methods: {
    isImage (mimeType) {
      return mimeType.includes('image/')
    },
    emitInput (v) {
      this.$emit('input', v)
    },
    fetchDetail (assetId) {
      this.$apollo.query({
        query: DETAIL_ASSET(this.templateType),
        variables: {
          assetType: this.assetType,
          assetId
        }
      }).then(resp => {
        this.formData.filename = resp.data.asset.filename
        this.formData.url = resp.data.asset.url
        this.formData.mimeType = resp.data.asset.mimeType
        this.emitInput(resp.data.asset.id)
      }).catch(this.$toasted.global.error)
    }
  },
  watch: {
    value: {
      handler (v) {
        if (!v) return

        this.fetchDetail(v)
      },
      immediate: true
    },
    assetIds (v) {
      if (v.length === 0) {
        this.formData.filename = ''
        this.formData.url = ''
        this.emitInput(null)
        return
      }

      this.fetchDetail(v[0])
    }
  },
  components: {
    AssetFormInputModal
  }
}

</script>

<style scoped>

</style>
<template>
  <div class="form-group">
    <AssetFormInputModal
      templateType="ChronowoodthAsset"
      :assetType="assetType"
      :title="label"
      :assetIds="value"
      @update:assetIds="emitInput"
      multiple>
      <template v-slot="{toggle}">
        <div>
          {{ label }}
          <span class="text-warning px-2 pointer" @click="toggle" v-if="!disabled">
            <fa icon="folder"></fa>
          </span>
        </div>
      </template>
    </AssetFormInputModal>

    <div class="row mt-2">
      <div 
        v-for="(ast,idx) in assetsMap" 
        :key="ast.id"
        class="col-6 col-sm-4 col-md-3">
        <div v-if="ast.url && isImage(ast.mimeType)">
          <img
            :src="ast.url" 
            class="img-fluid"
            :alt="ast.filename">
        </div>
        <div>{{ ast.filename }}</div>
        <div class="" v-if="!disabled">
          <span
            style="font-size: 16px;"
            class="mr-2"
            @click="moveLeft(idx)">
            <fa icon="arrow-left" class="text-primary pointer"></fa>
          </span>

          <span
            @click="getMarkdown(ast.filename, ast.url)"
            style="font-size: 20px;"
            class="mr-2">
            <fa :icon="['fab', 'markdown']" class="text-warning pointer"></fa>
          </span>

          <span
            style="font-size: 16px;"
            class="mr-2"
            @click="deleteAsset(idx)">
            <fa icon="trash" class="text-danger pointer"></fa>
          </span>

          <span
            style="font-size: 16px;"
            class="float-right"
            @click="moveRight(idx)">
            <fa icon="arrow-right" class="text-primary pointer"></fa>
          </span>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import AssetFormInputModal from './AssetFormInputModal.vue'
import { 
  LIST_ASSET,
} from './graph/asset.js'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      assets: []
    }
  },
  computed: {
    fixAssetIds () {
      return [...this.value].sort((a,b) => a-b)
    },
    assetsMap () {
      return this.value
        .map(v => this.assets.find(a => a.id === v))
        .filter(v => v)
    }
  },
  apollo: {
    assets: {
      query () {
        return LIST_ASSET("ChronowoodthAsset")
      },
      variables () {
        return  {
          assetType: this.assetType,
          q: {
            ids: this.fixAssetIds
          }
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    isImage (mimeType) {
      return mimeType.includes('image/')
    },
    emitInput (v) {
      this.$emit('input', v)
    },
    moveLeft (idx) {
      if (idx === 0) return

      const arr = [...this.value]
      const oldLeftId = arr[idx-1]
      const oldRightId = arr[idx]

      arr[idx - 1] = oldRightId
      arr[idx] = oldLeftId

      this.emitInput(arr)
    },
    moveRight (idx) {
      if (idx === this.assets.length - 1) return

      const arr = [...this.value]
      const oldLeftId = arr[idx]
      const oldRightId = arr[idx+1]

      arr[idx] = oldRightId
      arr[idx+1] = oldLeftId

      this.emitInput(arr)
    },
    deleteAsset(idx) {
      const arr = [...this.value]
      arr.splice(idx, 1)
      this.emitInput(arr)
    },
    getMarkdown (filename, url) {
      this.$copyText(`![${filename}](${url})`)
    },
  },
  components: {
    AssetFormInputModal
  }
}

</script>

<style scoped>

</style>